
import { httpRequest } from '../../services/network';
import { IQuestion } from '../../interfaces/Question';
import { scroll } from '../../services/shared';
import { STORE } from '../../store';
import { addSystemMessage, updateAppointmentMeta, updateTextInput } from '../../actions';
import { updateEnvironment } from '../../actions/environment.action';
import { renderQuestion } from '../RenderQuestion';

let expireTimer: any;

export const initializeAppointmentMeta = () => {
  STORE.dispatch(updateAppointmentMeta({
    dates: [],
    datesSent: 0,
    slots: [],
    slotsSent: 0,
    selectedValues: {
      date: '',
      slot: ''
    },
    lastQuestionSent: {},
    isActive: false
  }));
}
export const requestLivechat = () => {
  const { environment, configuration, design } = STORE.getState();

  httpRequest('PUT', 'live-chat-request', {
    uuid: localStorage.getItem('BotPenguin_User_uuid'),
    _bot: localStorage.getItem('__BotPenguin'),
    _customer: environment._user,
    _user: environment._chatWindowUser,
    emails: configuration?.alertSettings?.email?.emails,
    botName: design.name,
    _parent: environment.agencyData?._id,
    platform: 'WEBSITE'
  })
    .then(() => {
      
      STORE.dispatch(updateEnvironment({
        liveChat: true,
        disableStartButton: true,
        typing: false,
        activeQuestion: {
          ...environment?.activeQuestion,
          suggestions: []
        }
      }));

      STORE.dispatch(addSystemMessage({
        method: 'initiating-live-chat',
        title: configuration.generalSettings.waitingMessageTitle,
        subTitle: configuration.generalSettings.waitingMessage,
        titleColor: design.themeColor
      }));

      STORE.dispatch(updateTextInput({
        value: '', original: '', status: true
      }));

    /**
     * Author: Bhawna
     * Date: 25-06-2024
     * Summary: Earlier, live chat request expiration time was hardcoded as 120 seconds, 
     * but now it is configurable from dashboard, by deafult, 300 seconds
     */
      const expiryTime = configuration.chatMenu.liveChatRequestExpiryTime || 300000;

      expireTimer = setTimeout(() => {
        const currentUuid = localStorage.getItem('BotPenguin_User_uuid');
        expireLiveChatRequest(currentUuid);

        STORE.dispatch(addSystemMessage({
          method: 'no-agent-found',
          title: configuration.generalSettings.unavailabilityMessageTitle,
          subTitle: configuration.generalSettings.unavailabilityMessage,
          titleColor: design.themeColor
        }));
        renderNextQuestion();
      }, expiryTime);
      scroll();
    })
    .catch((e) => console.log(e, "Error in requesting live chat"));
}

export const expireLiveChatRequest = (uuid: string | null) => {
  httpRequest('PUT', `expire-chat-request/${uuid}`, {})
    .then(() => {
      STORE.dispatch(updateEnvironment({
        liveChatExpired: true,
        chatRequestRejected: true,
        chatRequestAccepted: false,
        liveChat: false,
        initiateLiveChat: false,
        disableStartButton: false,
        agentId: undefined
      }));
      scroll();
      localStorage.removeItem('agentId');
      localStorage.removeItem('bp-wsn');
    })
    .catch();
}

export const renderNextQuestion = () => {
  const { environment, flows } = STORE.getState();
  
  const activeQuestion = environment.activeQuestion;
  const activeQuestionIndex = (flows[0].questions || []).findIndex((question: IQuestion) => question?.id === activeQuestion?.id);
  // Code for rendering next question if active questin does not have a target next defined else send next index message
  if (activeQuestion?.next && activeQuestion?.next?.target) {
    const nextQuestionIndex = (flows[0].questions || []).findIndex((question: IQuestion) => question.id === activeQuestion.next.target);
    if (nextQuestionIndex !== -1 ) {
      renderQuestion(nextQuestionIndex);
    } else {
      renderQuestion(0);
    }
  } else {
    renderQuestion(activeQuestionIndex + 1);
  }
}

export const clearRequestTimer = () => {
  if (expireTimer) {
    clearTimeout(expireTimer);
  }
}

export const transferToBot = async (isRestart = false) => {
  const { environment } = STORE.getState();

  initializeAppointmentMeta();

  if (localStorage.getItem('agentId')) {
    await httpRequest('PUT', 'bot-update-handler/bot',
      {
        uuid: environment.uuid,
        transferredBy: "USER",
        _transferrer: environment._chatWindowUser,
        agentId: localStorage.getItem('agentId'),
      })
      .then(async () => {
        renderNextQuestion();
        localStorage.removeItem('agentId');
        localStorage.removeItem('bp-wsn');

        STORE.dispatch(updateEnvironment({
          liveChat: false,
          chatRequestAccepted: false,
          disableStartButton: false,
          agentId: undefined,
          enableTextInput: true,
        }));
        localStorage.removeItem('agentId');
      }).catch();
  } else {
    clearRequestTimer();
    renderNextQuestion();
    
    if (!localStorage.getItem('agentId')) {
      expireLiveChatRequest(environment.uuid);
    }

    STORE.dispatch(updateEnvironment({
      liveChat: false,
      chatRequestAccepted: false,
      disableStartButton: false,
      agentId: undefined,
    }));

    localStorage.removeItem('agentId');
    localStorage.removeItem('bp-wsn');
  }

}

export const transferToWhatsapp = async () => {
  const { environment } = STORE.getState();

  if (localStorage.getItem('agentId')) {
    await
      httpRequest('PUT', 'bot-update-handler/whatsapp',
        {
          uuid: environment.uuid,
          transferredBy: "USER",
          _transferredTo: localStorage.getItem('agentId'),
          agentId: localStorage.getItem('agentId'),
        })
        .then(() => {
          STORE.dispatch(updateEnvironment({
            chatRequestRejected: false,
            chatRequestAccepted: false,
            liveChat: false,
            disableStartButton: false,
            agentId: undefined,
            typing: false,
          }));
          STORE.dispatch(updateTextInput({
            status: false,
            value: '', original: ''
          }));
          window.open(
            `https://wa.me/` + localStorage.getItem('bp-wsn'),
            '_blank' // <- This is what makes it open in a new window.
          );
          localStorage.removeItem('agentId');
          localStorage.removeItem('bp-wsn');

          scroll();

        }).catch();
  }
}

