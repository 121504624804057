import { httpRequest } from './network';

import CryptoJS from 'crypto-js';
import { IOptions, IQuestion } from "../interfaces/Question";
import { STORE } from '../store';
import { newMessageSound } from './notification-handler';
import { updateReplyMessage } from '../actions';

export const queryAIHandler = (data: any) => new Promise((resolve, reject) => {
  const { environment }: any = STORE.getState();
  
  httpRequest('POST', `artificial-intelligence/ai-response`, { binary: encryptPayload({ ...data, uuid: environment.uuid || '' }) })
    .then((resp: any) => {
      resolve(resp.data);
    })
    .catch(err => { reject(err) })

});

export const encryptPayload = (payload: any) => {
  const newPayload = JSON.stringify(payload);

  return CryptoJS
    .AES
    .encrypt(newPayload, 'user-payload')
    .toString();
};

export const saveMessage = async (payload: any) => {
  try {
    const { environment, configuration }: any = STORE.getState();
    // if user is not present in local storage set it
    if (!localStorage.getItem('__BotPenguinUser')) {
      localStorage.setItem('__BotPenguinUser', environment._user);
      localStorage.setItem('__BotPenguin', environment._id);
    }

    await httpRequest('POST', 'save-response', {
      binary: encryptPayload({
        payload,
        _bot: environment._id,
        _user: environment._user,
        uuid: environment.uuid,
        profile: environment.profile,
        isLiveChat: !!localStorage.getItem('agentId'),
        agentId: localStorage.getItem('agentId') || environment._user,
      })
    });
    if (payload.messagedBy === 'bot') {
      newMessageSound(configuration.generalSettings.notificationSound);
    }

    if (Object.keys(payload?.replyInfo || {}).length) {
      STORE.dispatch(updateReplyMessage(null));
    }
    return true;
  } catch (error: any) {
    console.log(JSON.stringify('Error saving response', error))
  }
}

export const updateUUID = async (payload: any) => {
  try {
    const { environment, meta }: any = STORE.getState();

    await httpRequest('PUT', 'update-meta', {
      binary: encryptPayload({
        payload: {
          ...meta,
          ...payload
        },
        _bot: environment._id,
        _user: environment._user,
        uuid: environment.uuid,
        profile: environment.profile
      })
    });
  } catch (error) {
    console.log('Error in updating UuId', error);
  }
};

export const addInstallation = async () => {
  try {
    const { environment }: any = STORE.getState();

    const installations = environment.installations || [];
    let baseUrl = window.location.origin;
    if (!installations.find((data: any) => data.siteUrl === baseUrl)) {
      await httpRequest('POST', 'bot-installation', {
        binary: encryptPayload({
          botId: localStorage.getItem('__BotPenguin'),
          _user: localStorage.getItem('__BotPenguinUser'),
          siteUrl: baseUrl
        })
      });
    }
  }
  catch (error) {
    console.log(error, "error in Adding Installations");
  }
};

export const actionBasedOnChatResponse = async (activeQuestion: IQuestion | any, text: string, _userWebsite: string, _customer?: string) => {
  try {
    if (activeQuestion?.type === 'button') {
      const selectedOption = activeQuestion?.options?.find((option: IOptions) => option.value === text);
      const payload = encryptPayload({
        tagIds: selectedOption?.actionBasedChatResponse?.tagIds ?? [],
        status: selectedOption?.actionBasedChatResponse?.status ?? 'OPEN',
        agentId: selectedOption?.actionBasedChatResponse?.agentId ?? undefined,
        isSubscriber: selectedOption?.actionBasedChatResponse?.isSubscriber ?? true,
        searchQuery: { _userWebsite, _customer }
      })
      await httpRequest('PUT', `chat-response-actions`, { binary: payload });
    }
    return true;
  } catch (err) {
    throw new Error(JSON.stringify({ 'Error in actionBasedOnChatResponse': err }));
  }
};
