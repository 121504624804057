import { UPDATE_REPLY_MESSAGE } from "../actions";
import { createNewReference } from "../services/shared";


const initialState = {};

const replyMessageReducer = ( state = initialState, action?: any ) => {
  if (action.type === UPDATE_REPLY_MESSAGE) {
    return createNewReference({ ...(action.payload || {}) });
  }
  return state;
};

export { replyMessageReducer };